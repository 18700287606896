@font-face {
  font-family: 'Cookie';
  font-style: normal;
  font-weight: 400;
  src: local('Cookie-Regular'), url(https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbf9kgqS1NDNpg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

.App {
  text-align: center;
}
* {
  user-select: none;
}
input {
  -webkit-user-select: text;
  user-select: text;
}

body {
  background: #222;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.buildorder-list {
  flex-grow: 1;
}

.control {
  width: 40px;
  height: 40px;
  background: black;
  color: white;
  flex-shrink: 0;
  line-height: 40px;
  cursor: pointer;
  font-size: 23px;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.game-content-useage-notice {
  font-size: 0.6em;
  color: white;
  text-align: center;
  padding: 0.5em 1em;
  user-select: none;
}

.game-content-useage-notice a {
  color: white;
}

.buildorderstep-icon {
  color: transparent;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}
.buildOrder {
  padding-top: 106px;
  padding-bottom: 86px;
}
.flex {
  display: flex;
  align-items: center;
}
.add-step, .upload-build {
  background: none;
  border: none;
  background: black;
  padding: 0.5em 1em;
  margin: 0.5em 0;
  width: 100%;
  color: white;
  font-size: 1.3em;
   svg {
     margin-right: 1em;
   }
}
.edit-buildOrder {
  .buildorder-step-wrap {
    display: flex;
    width: 100%;
    background: black;
    padding: 0;
    margin: 0.5em 0;
    justify-content: space-between;
    .buildorderstep {
      padding: 1em 0;
    }
  }

  .controls {
    color: #999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .controls svg {
    font-size: 1.6em;
    padding: 0.2em;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }

  .buildorderstep {
    flex-grow: 1;
  }
}
.edit-header {
  background: black;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  align-items: center;

  input {
    max-width: 70vw;
    background: none;
    color: white;
    border: none;
    border-bottom: 1px solid white;
    flex-grow: 1;
    font-size: 1.2em;
    font-weight: bold;
    justify-self: flex-end;
    min-width: 0;
  }
  svg.svg-inline--fa.fa-check.fa-w-16 {
    font-size: 1.5em;
    color: white;
    margin-left: 0.5em;
  }
}
.select-icon {

  position: fixed;
  padding: 0.5em;
  background: #222;
  z-index: 1;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 10vh;
  left: 50%;
  margin-left: -36px;
  .buildorderstep-icon {
    margin-right: 0;
  }
  .flex {
    align-items: flex-start;
    label {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      display: block;
      font-size: 0.8em;
      width: auto;
    }
  }

}
.select-icon-wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  z-index: 10;
}

.buildorder-header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  background: black;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
  flex-wrap: wrap;
  border-bottom: solid 0.5em #222;

  > .flex > .buildorderstep-icon {
    margin-right: 4px;
  }
  .flex {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.2em;
  }

  .buildorder-name {
    flex-grow: 1;
    text-align: left;
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    background: black;
    margin-right: 10px;
    padding: 0.2em;
    white-space: nowrap;
    max-width: calc(100vw - 110px);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .resources {
    flex-grow: 1;
    justify-content: space-between;
  }

  .time {
    margin-left: 0.5em;
  }

  .time-display {
    padding: 0.4em 0;
    background: black;
    color: white;
    border: ridge #333 3px;
    width: 70px;
    text-align: center;
    white-space: nowrap;
    span {
      padding: 0;
      margin: 0;
    }
    input {
      margin: 0;
      padding: 0;
    }
  }

  .resources .buildorderstep-icon {border: ridge 3px #333;}


  .resources {
    display: flex;
  }

  .time {
    display: flex;
    align-items: center;
  }
  .buildorderstep-icon {
    background-size: 2000%;
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 0;
    margin: 0;
    position: relative;
    label {
      position: absolute;
      bottom: -1px;
      right: 0px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      width: 12px;
      text-align: right;
    }
  }
}
.buildorder-step-wrap {
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  .buildorderstep {
    padding: 1em 2em;
    border: solid 1px black;
    background: black;
    color: #eee;
    font-size: 18px;
    font-weight: bold;
    position:relative;
    label.end-time {
      position: absolute;
      bottom: 0.5em;
      right: 0.5em;
      font-size: 0.6em;
      font-weight: normal;
      color: #666;
    }
    .sub-steps {
      border-top: dashed #666 1px;
      margin-top: 1em;
      width: calc(100% + 2rem);
      margin-left: -1rem;
    }
    .step-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      > * {
        margin-right: 10px;
      }
      > *:last-child {
        margin-right: 0;
      }
      span.number {
        min-width: 50px;
        text-align: center;
      }
    }
    .buildorderstep {
      transform: scale(0.66);
      box-sizing: border-box;
      height: 60px;
      width: calc(100% * 3 / 2);
      margin-left: -25%;
    }

    .buildorderstep .end-time {
      display: none;
    }

  }
}
.buildorderstep svg.fa-caret-right {
  font-size: 30px;
}
.buildorderstep.research .step-info {
  justify-content: space-around;
}
.time-display input {
  background: none;
  width: 1.5em;
  border: none;
  color: white;
  font-size: 1em;
  -moz-appearance:textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display:none;
    -webkit-appearance: none;
    margin: 0;
  }

}

.time-display input:first-child {
  text-align: right;
}
.buildorderstep-icon {
  margin-right: 13px;
  width: 50px;
  height: 50px;
  border: solid black;
  flex-shrink: 0;
  position: relative;
  label {
    background: black;
    padding: 0 0.3em;
    border-radius: 5px;
    bottom: -5px;
    right: -8px;
    color: white;
    position: absolute;
  }
  .icon-text {
    display: none;
  }
}

.buildorder-tracker {
  position: absolute;
  top: 427px;
  color: red;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: -12px;
  transition: top 0.3s;

  .line {
    flex-grow: 1;
    border: solid 2px red;
    height: 0px;
    border-radius: 100px;
  }

  svg {
    margin-left: 0.2em;
    margin-right: 0.2em;
    font-size: 1.3em;
  }

}
.buildorder.list-item {
  background: black;
  /* display: block; */
  margin-bottom: 0.5em;
  color: white;
  display: flex;
  font-size: 1.2em;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0.5em;
  .control {
    margin-left: 1em;
    font-size: 1em;
  }
  label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .label-wrap {
    display: flex;
    flex-direction: column;
    width: 80vw;
    box-sizing: border-box;
    flex-shrink: 1;
  }

  label.attribution {
    font-size: 0.6em;
    text-align: center;
    margin-top: 0.5em;
    color: #999;
  }
}

.buildorder.list-item .buildorderstep-icon {
  margin-right: 1em;
  width: 34px;
  height: 34px;
  background-size: 2000%;
}

.buildorder-list {
  color: white;
  div.menu {
    background: none;
    padding: 0;
    justify-content: center;
    font-size: 1em;
    outline: none;
    border: none;
    align-items: center;
    display: flex;
    text-align: center;
    position: relative;
    top: auto;
    right: auto;
    margin-left: 0.8em;
    font-size: 1em;
    flex-direction: column;
    border: 1px solid black;
    .menu-item {
      padding: 1em;
      display: flex;
      justify-content: flex-start;
    }
    svg {
      margin-right: 0;
    }
    .menu {
      position: absolute;
      top: 40px;
      right: 0px;
      background: #222;
      font-size: 1.4rem;
      justify-content: space-between;
    }

    .menu .menu-item:last-child {
      justify-content: space-between;
    }

    .menu .menu-item label {
      text-align: left;
      margin-left: 1em;
    }
  }

  h1 {
    background: black;
    margin: 0;
    padding: 1em;
    margin-bottom: 0.3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > button {
    background: black;
    border: none;
    margin-bottom: 0.5em;
    margin-top: 0;
    font-size: 1.3em;
    height: 51.8px;
    display: flex;
    width: 100%;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 1em;
    }
  }

}

.bmc-btn svg {
  height: 30px !important;
  margin-bottom: 1px !important;
  box-shadow: none !important;
  border: none !important;
  vertical-align: middle !important;
  transform: scale(0.9);
}

span.bmc-btn-text {
  user-select: none;
}
.bmc-btn {
  color: #fff;
  background-color: #000000 !important;
  height: 60px;
  border-radius: 12px;
  font-size: 26px;
  border: none;
  padding: 0px 24px;
  line-height: 27px;
  text-decoration: none !important;
  display: inline-flex !important;
  align-items: center;
  font-family: 'Cookie', cursive !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  margin: 0.5em 1em;
  min-height: 2em;
  width: fit-content;
  align-self: center;
}

.bmc-btn:hover, .bmc-btn:active, .bmc-btn:focus {
  text-decoration: none !important;
  cursor: pointer;
}

.bmc-btn{
  .bmc-btn-text {
    margin-left: 18px;
    display: inline;
    line-height: 0;
  }

  .logo-outline {
    fill: #fff;
  }

  .logo-coffee {
    fill: #fd0;
  }
}
.menu {
  position: absolute;
  top: 50px;
  right: 10px;
  background: #222;
  padding: 0.5em 1em;
  z-index: 1;
  .menu-item {
    width: 60vw;
    display: flex;
    align-items: center;
    padding-bottom: 0.5em;
  }

  .menu-item label {
    text-align: left;
    flex-grow: 1;
  }
}

.menu svg {
  margin-right: 1em;
}

.sortable-ghost {
  opacity: 0.2;
}
.sortable-chosen {
  border: solid 2px white;
}
.edit-buildOrder .buildorder-step-wrap {
  flex-direction: column;
  color: white;
}
.buildorderstep-icon.nothing {background-image: none;background: #222;}

.buildorder-step-wrap.substep {
  > div:first-child {
    background: #222;
    .buildorderstep {
      background: black;
      transform: scale(0.9);
    }
  }
}
.buildorder-step-edit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .buildorder-step-edit {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .rc-select.rc-select-single.rc-select-show-arrow {
    width: 100%;
  }

  button.remove {
    margin-bottom: 0;
  }

  button {
    width: 100%;
    background: #222;
    border: none;
    padding: 1em 1em;
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    cursor: pointer;

    svg {
      margin-left: 0.5em;
    }
  }

  > * {
    margin-bottom: 0.5em;
  }
  .rc-select.rc-select-single.rc-select-show-arrow {
    background: #222;
    width: 100%;
    position: relative;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    font-size: 1.4em;
    align-items: center;
    border-top: solid 4px black;
  }

  span.rc-select-arrow-icon:after {content: "▶";font-size: 1em;margin-right: 0.7em;}

  .rc-select-open span.rc-select-arrow-icon:after {content: "▼";}

}
.rc-select-dropdown {
  background: #222;
  color: white;

  &.rc-select-dropdown-hidden {
    display: none;
  }


  .rc-select-item-option-content {
    padding: 0.5em 1em;
  }

  .rc-select-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span.rc-select-item-option-state {
    display: none;
  }

  .rc-select-item.rc-select-item-option-selected {
    background: #555;
  }
}
.rc-select{
  span.rc-select-selection-search {
    display: none;
  }

  .rc-select-selector {
    width: 100%;
    padding: 0.5em 1em;
  }
}
.play-controls {
  z-index: 10;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  box-sizing: border-box;
  .play-pause-control {
    border: outset 3px #333;
    border-radius: 10px;
    width: 70px;
    height: 70px;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
    background: black;
    flex-shrink: 0;
    line-height: 70px;
    cursor: pointer;
    font-size: 23px;
  }
  .speedcontrol-button {
    flex-shrink: 1;
    flex-grow: 1;
    background: #000;
    border: outset 3px #333;
    width: 30vw;
    height: 70px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 33px;
    z-index: 10;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.5);
    border-right-color: #666;
    border-bottom-color: #666;
    cursor: pointer;

    &.back {
      right: auto;
      left: 10px;
    }
  }
}

.upload-build-order-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.upload-build-order {
  width: calc(100vw - 40px);
  background: #333;
  border: 5px outset #222;
  box-sizing: border-box;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right-color: #666;
  border-bottom-color: #666;
  padding-bottom: 0;
  input.file-upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  h2 {
    margin-top: 0;

  }
  .controls {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    width: calc(100% + 4rem);
    flex-direction: column;
  }

  .controls button {
    background: black;
    color: white;
    border: outset 3px #222;
    padding: 1em 2em;
    font-weight: bold;
    outline: none;
    font-size: 0.8em;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
    background: black;
    top: -1rem;
    position: relative;
    width: calc(100% + 4rem);
    padding: 1em;
    box-sizing: border-box;
  }
  label.file-name {
    margin-bottom: 1.5em;
  }
  label.file-upload-label {
    padding: 1em;
    font-weight: bold;
    border: outset 5px #222;
    font-size: 1em;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
  }

  label.file-upload-label svg {
    margin-right: 1em;
    font-size: 1.3em;
  }
  .controls button {
    margin-bottom: 0.5em;
    width: 100%;
    box-sizing: border-box;
    display: block;
    border: none;
    font-size: 1em;
    padding: 1.5em;
    cursor: pointer;
  }

}

label.resource-changes {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 0.6em;
  color: #666;

  .change {
    display: flex;
    align-items: center;

    .buildorderstep-icon {
      width: 20px;
      height: 20px;
      margin-right: 0;
      background-size: 2000%;
    }
    span.number {
      margin-left: 0.2em;
    }
  }
}

.buildOrder.per-villager-marks {
  .step-marks {
    display: flex;
  }
}
.step-marks {
  display: none;
  position: absolute;
  top: -0.25rem;
  left: 0;
  height: calc(100% + 0.5rem);
  width: 6px;
  flex-direction: column;
  justify-content: space-between;
  .step-marker {
    height: 6px;
    margin-top: 3px;
    width: 100%;
    background: #333;
    border-radius: 0 200px 200px 0;
  }
  .step-marker:first-child {
    height: 0;
  }

  .step-marker:last-child {
    height: 0;
  }
}

.sub-steps .step-marks {
  display: none;
}
.settings {
  color: white;
  flex-grow: 1;
  .property {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  h1 {
    background: black;
    margin: 0;
    padding: 1em;
  }
  > button {
    background: black;
    border: none;
    margin-bottom: 0.5em;
    font-size: 1.3em;
    height: 51.8px;
    display: flex;
    width: 100%;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 0.5em;
    svg {
      margin-right: 1em;
    }
  }
}

.property {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.5em;
  .edit-buildorderstep-icon .buildorderstep-icon {
    margin-right: 0;
  }
  input {
    background-color: #333;
    border: none;
    padding: 0.5em 1em;
    flex-grow: 1;
    color: white;
    font-size: 1em;
    text-align: right;
    min-width: 0;
  }
  label {
    margin-right: 1em;
    text-transform: capitalize;
    width: 130px;
    text-align: left;

    &:after {
      content: ":";
    }
  }


  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
}

.timer {
  position: fixed;
  top: 20%;
  height: 60%;
  font-size: 50vw;
  width: 80vw;
  background: #000;
  color: white;
  box-sizing: border-box;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 10vw;
  border-radius: 25px;
  border: outset #333 5px;
  border-right-color: #777;
  border-bottom-color: #777;
  cursor: pointer;
  label.time {
    margin-left: 0;
  }
}
.research-list {
  width: 100%;
  align-items: center;
}

.research-list .tech {
  display: flex;
  align-items: center;
  justify-content: center;
}

.version {
  font-size: 0.8em;
  text-align: right;
  padding: 10px;
}

.new-content-loaded {
  font-size: 0.8em;
  padding: 1em;
  top: 0;
  background: #ffb307;
  font-weight: normal;
  width: 100vw;
  display: none;
  p {
    margin: 0;
  }
  p:first-child{
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }
  p:last-child {
    font-style: italic;
  }
}
.install-app{
  display: none;
}
.install-app.browser-tab {
  position: fixed;
  bottom: 1em;
  background: #222;
  width: 80%;
  left: 10%;
  padding: 1em;
  box-sizing: border-box;
  z-index: 10;
  color: white;
  font-size: 1.5em;
  border: outset #333 5px;
  border-right-color: #666;
  border-bottom-color: #666;
  background: #090909;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  &.hidden {
    display: none;
  }
  &.shown {
    display: flex;
  }

  svg.fa-mobile-alt {
    margin-right: 0.7em;
  }
  button {
    outline: none;
    border: none;
    color: white;
    font-size: 1em;
    padding: 0.5em 1em;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    padding-right: 0.5em;
    font-size: 0.8em;
  }
}